import { useCallback } from 'react'

import { Icon } from '@fto/icons'

import { LOGIN_ROUTE, makeRoute, USER_PROFILE_ROUTE } from '@root/constants/routes'
import { addModal, Button, Flex, Typography } from '@fto/ui'
import { User } from '@root/types/user'

import styles from './index.module.scss'
import { LOGOUT } from '@root/store/auth/auth.slice'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { useTranslation } from 'react-i18next'
import { $userCanSeeExtendedSettings } from '@root/store/auth/selectors'
import { MODAL_NAMES } from '@root/constants/modalNames'

type VoidFunc = () => void

export const optionsRenderer = (email: User['email'], navigate: (route: string) => void, isCheckout: boolean) => {
    const { t } = useTranslation()
    const canSeeAdminSettings = useAppSelector($userCanSeeExtendedSettings)

    const dispatch = useAppDispatch()
    const navigateToProfile = useCallback(
        (onClose: VoidFunc) => {
            navigate(makeRoute(USER_PROFILE_ROUTE))
            onClose()
        },
        [navigate]
    )

    const logOut = useCallback((onClose: VoidFunc) => {
        dispatch(LOGOUT())
        onClose()
        navigate(makeRoute(LOGIN_ROUTE))
    }, [])

    return useCallback(
        (onClose: VoidFunc) => {
            return (
                <Flex direction='column' gap={4} className={styles.Root}>
                    {email && (
                        <Typography type='subtext-regular' color='gray-600'>
                            {email}
                        </Typography>
                    )}
                    {!isCheckout && (
                        <Flex
                            gap={8}
                            alignItems='center'
                            className={styles.Option}
                            onClick={() => navigateToProfile(onClose)}
                        >
                            <Icon name='settings' size={16} color='var(--color-gray-1000)' />
                            <Typography type='interface-medium' color='gray-1000'>
                                {t('profile.settings')}
                            </Typography>
                        </Flex>
                    )}

                    {canSeeAdminSettings && (
                        <Flex
                            gap={8}
                            alignItems='center'
                            className={styles.Option}
                            onClick={() => addModal(MODAL_NAMES.dashboard.adminSettings)}
                        >
                            <Icon name='settings' size={18} color='var(--color-gray-1000)' />
                            <Typography type='interface-medium' color='gray-1000'>
                                {t('ribbon.profile.projectSettings')}
                            </Typography>
                        </Flex>
                    )}

                    <button className={styles.FeatureBaseButton}>
                        <Flex
                            gap={8}
                            className={styles.Option}
                            alignItems='center'
                            onClick={() => {
                                if (window.Tawk_API) {
                                    window.Tawk_API.start()
                                    window.Tawk_API.maximize()
                                }
                            }}
                        >
                            <Icon name='contact-support' size={18} />
                            <Typography type='interface-medium' color='gray-1000'>
                                {t('ribbon.help.contactSupport')}
                            </Typography>
                        </Flex>
                    </button>
                    <Flex gap={8} alignItems='center' className={styles.Option} onClick={() => logOut(onClose)}>
                        <Icon name='log-out' size={16} color='var(--color-gray-1000)' />
                        <Typography type='interface-medium' color='gray-1000'>
                            {t('auth.logOut')}
                        </Typography>
                    </Flex>
                </Flex>
            )
        },
        [email]
    )
}
