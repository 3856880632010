import { USE_CROWDIN } from '@root/constants/localStorageKeys'
import { createSlice } from '@reduxjs/toolkit'
import { setDefaultLang, setPseudoLangForCrowdin } from '@root/i18n'

export type AppSettingsState = {
    useCrowdin: boolean
}

export const initState: AppSettingsState = {
    useCrowdin: localStorage.getItem(USE_CROWDIN) === 'true'
}

export const slice = createSlice({
    name: 'appSettings',
    initialState: initState,
    reducers: {
        SET_CROWDIN: (state, action) => {
            state.useCrowdin = action.payload
            localStorage.setItem(USE_CROWDIN, action.payload)

            // side effects
            if (action.payload) {
                setPseudoLangForCrowdin()
            } else {
                setDefaultLang().then(() => {
                    window.location.reload()
                })
            }
        }
    }
})

export const { SET_CROWDIN } = slice.actions

export default slice.reducer
