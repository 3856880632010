import { t } from 'i18next'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TDateTime, DateUtils } from '../../delphi_compatibility/DateUtils'
import StrangeSituationNotifier from '@fto/lib/common/StrangeSituationNotifier'
import CommonConstants from './CommonConstants'

//former DateTimeProcs
export class TimeframeUtils {
    public static readonly M1_Timeframe = 1
    public static readonly M5_Timeframe = 5
    public static readonly M15_Timeframe = 15
    public static readonly M30_Timeframe = 30
    public static readonly H1_Timeframe = 60
    public static readonly H4_Timeframe = 240
    public static readonly D1_Timeframe = DateUtils.MinutesPerDay
    public static readonly W1_Timeframe = DateUtils.MinutesPerWeek
    public static readonly MonthlyTimeframe = 30 * DateUtils.MinutesPerDay

    public static IsWeirdTimeframe(periodInMinutes: number): boolean {
        if (periodInMinutes <= 0) throw new StrangeError('Invalid timeframe')
        else if (periodInMinutes < DateUtils.MinutesPerDay && DateUtils.MinutesPerDay % periodInMinutes === 0) {
            return false
        } else {
            switch (periodInMinutes) {
                case DateUtils.MinutesPerDay: {
                    return false
                }
                case DateUtils.MinutesPerWeek: {
                    return false
                }
                case 30 * DateUtils.MinutesPerDay: {
                    return false
                }
                case 90 * DateUtils.MinutesPerDay: {
                    return false
                }
                default: {
                    // eslint-disable-next-line sonarjs/prefer-single-boolean-return
                    if (periodInMinutes % DateUtils.MinutesPerDay === 0) {
                        return false
                    } else {
                        return true
                    }
                }
            }
        }
    }

    // Get period bounds for requested date
    public static GetPeriod(dateTime: TDateTime, periodInMinutes: number): [TDateTime, TDateTime] {
        let StartDateTime: TDateTime, EndDateTime: TDateTime

        const DaySeconds = 24 * 60 * 60
        const dateWithNoTime = DateUtils.GetDateWithNoTime(dateTime)
        if (periodInMinutes === 0) {
            // 1 second period
            const secs = Math.round((dateTime % 1) * DaySeconds)
            StartDateTime = dateWithNoTime + secs / DaySeconds
            EndDateTime = dateWithNoTime + (secs + 1) / DaySeconds
        } else if (periodInMinutes < DateUtils.MinutesPerDay && DateUtils.MinutesPerDay % periodInMinutes === 0) {
            // if period less than day and period fits into the day (1 hour, 15 mins, etc...)
            // remove date part and get part of the day
            let partOfTheDayPassed = DateUtils.GetTimeOnly(dateTime)
            if (partOfTheDayPassed >= DateUtils.OneDay) partOfTheDayPassed = DateUtils.OneDay - DateUtils.OneSecond
            const minsPassedForThisDay = Math.floor(
                partOfTheDayPassed * DateUtils.MinutesPerDay + CommonConstants.DATE_PRECISION_MINIMAL_STEP_AS_DATETIME
            )
            const periodsPassedForThisDay = Math.floor(minsPassedForThisDay / periodInMinutes) * periodInMinutes
            StartDateTime = dateWithNoTime + periodsPassedForThisDay / DateUtils.MinutesPerDay
            EndDateTime = dateWithNoTime + (periodsPassedForThisDay + periodInMinutes) / DateUtils.MinutesPerDay
            // eslint-disable-next-line unicorn/prefer-switch
        } else if (periodInMinutes === DateUtils.MinutesPerDay) {
            // if period equals to day
            StartDateTime = dateWithNoTime
            EndDateTime = StartDateTime + 1
        } else if (periodInMinutes === DateUtils.MinutesPerWeek) {
            // if period equals to week
            StartDateTime = DateUtils.StartOfTheWeek(dateTime)
            EndDateTime = StartDateTime + DateUtils.DaysPerWeek
        } else if (periodInMinutes === this.MonthlyTimeframe) {
            // if period equals to 1 month
            StartDateTime = DateUtils.StartOfTheMonth(dateTime)
            EndDateTime = StartDateTime + DateUtils.DaysInMonth(dateTime)
        } else if (periodInMinutes === this.MonthlyTimeframe * 4) {
            // if period equals to 4 months
            ;({ StartDateTime, EndDateTime } = TimeframeUtils.GetMonthlyPeriod(dateTime, 4))
        } else if (periodInMinutes === this.MonthlyTimeframe * 6) {
            // if period equals to 6 months
            ;({ StartDateTime, EndDateTime } = TimeframeUtils.GetMonthlyPeriod(dateTime, 6))
        } else if (periodInMinutes === this.MonthlyTimeframe * 2) {
            // if period equals to 2 months
            ;({ StartDateTime, EndDateTime } = TimeframeUtils.GetMonthlyPeriod(dateTime, 2))
        } else if (periodInMinutes === this.MonthlyTimeframe * 3) {
            // if period equals to 3 months
            ;({ StartDateTime, EndDateTime } = TimeframeUtils.GetMonthlyPeriod(dateTime, 3))
        } else if (periodInMinutes % DateUtils.MinutesPerDay === 0) {
            // if period contains whole number of days
            const periodInDays = Math.round(periodInMinutes / DateUtils.MinutesPerDay)
            StartDateTime = Math.floor(dateWithNoTime / periodInMinutes) * periodInMinutes
            EndDateTime = StartDateTime + periodInDays
        } else {
            // if period does not fit into the day (38 min, 13 min)
            const _1960 = DateUtils.EncodeDate(1960, 1, 1)
            const dateTimeInSeconds = DateUtils.toUnixTimeSeconds(dateTime)
            const baseSeconds = DateUtils.toUnixTimeSeconds(_1960)

            const elapsedSeconds = dateTimeInSeconds - baseSeconds
            const periodSeconds = periodInMinutes * DateUtils.SecondsPerMinute

            const alignedSeconds = Math.floor(elapsedSeconds / periodSeconds) * periodSeconds

            StartDateTime = DateUtils.fromUnixTimeSeconds(baseSeconds + alignedSeconds)
            EndDateTime = DateUtils.fromUnixTimeSeconds(baseSeconds + alignedSeconds + periodSeconds)
        }
        return [StartDateTime, EndDateTime]
    }

    private static GetMonthlyPeriod(dateTime: TDateTime, numberOfMonths: number) {
        if (![1, 2, 3, 4, 6].includes(numberOfMonths)) {
            throw new StrangeError('Invalid number of months in a timeframe')
        }
        const firstYear = DateUtils.YearOf(dateTime)
        let lastYear = firstYear
        let firstMonth = DateUtils.MonthOf(dateTime)
        firstMonth = Math.floor((firstMonth - 1) / numberOfMonths) * numberOfMonths + 1
        let lastMonth = firstMonth + numberOfMonths
        if (lastMonth > 12) {
            lastMonth -= 12
            lastYear++
        }
        const StartDateTime = DateUtils.EncodeDate(firstYear, firstMonth, 1)
        const EndDateTime = DateUtils.EncodeDate(lastYear, lastMonth, 1)
        return { StartDateTime, EndDateTime }
    }

    public static GetPeriodStart(dateTime: TDateTime, periodInMinutes: number): TDateTime {
        return TimeframeUtils.GetPeriod(dateTime, periodInMinutes)[0]
    }

    public static GetPeriodEnd(dateTime: TDateTime, periodInMinutes: number): TDateTime {
        return TimeframeUtils.GetPeriod(dateTime, periodInMinutes)[1]
    }

    public static GetLongTimeframeName(period: number): string {
        const DayMins = 60 * 24
        const WeekMins = DayMins * 7
        const MonthMins = DayMins * 30

        switch (true) {
            case period === 1: {
                return t('timeframeLongNames.minute', { count: 1 })
            }
            case period < 60: {
                return t('timeframeLongNames.minute', { count: period })
            }
            case period === 60: {
                return t('timeframeLongNames.hour', { count: 1 })
            }
            case period === DayMins: {
                return t('timeframeLongNames.day', { count: 1 })
            }
            case period === WeekMins: {
                return t('timeframeLongNames.week', { count: 1 })
            }
            case period === MonthMins: {
                return t('timeframeLongNames.month', { count: 1 })
            }
            case period % WeekMins === 0: {
                return t('timeframeLongNames.week', { count: period / WeekMins })
            }
            case period % DayMins === 0: {
                return t('timeframeLongNames.day', { count: period / DayMins })
            }
            case period % 60 === 0: {
                return t('timeframeLongNames.hour', { count: period / 60 })
            }
            default: {
                return t('timeframeLongNames.minute', { count: period })
            }
        }
    }

    //TODO: should we translate this?
    public static GetShortTimeframeName(period: number): string {
        // eslint-disable-next-line unicorn/prefer-switch
        if (period % this.MonthlyTimeframe === 0) {
            return `${period / this.MonthlyTimeframe}MN`
        } else if (period % this.W1_Timeframe === 0) {
            return `${period / this.W1_Timeframe}W`
        } else if (period % this.D1_Timeframe === 0) {
            return `${period / this.D1_Timeframe}D`
        } else if (period % this.H1_Timeframe === 0) {
            return `${period / this.H1_Timeframe}H`
        } else if (period < 60) {
            return `${period}m`
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `Invalid period passed to GetShortTimeframeName ${period}`
            )
            return `${period}m`
        }
    }

    // NOTE: t is i18next.t (pass hook value)
    public static GetTimeframeNameLabel(
        period: number,
        defaultValueParams?: { localeKey: string; localeParamsKey?: string }
    ): { label: string; isValid: boolean } {
        if (period % this.MonthlyTimeframe === 0) {
            const count = period / this.MonthlyTimeframe
            return { label: t('timeframes.monthCountValue', { count }), isValid: true }
        } else if (period % this.W1_Timeframe === 0) {
            const count = period / this.W1_Timeframe
            return { label: t('timeframes.weekCountValue', { count }), isValid: true }
        } else if (period % this.D1_Timeframe === 0) {
            const count = period / this.D1_Timeframe
            return { label: t('timeframes.dayCountValue', { count }), isValid: true }
        } else if (period % this.H1_Timeframe === 0) {
            const count = period / this.H1_Timeframe
            return { label: t('timeframes.hourCountValue', { count }), isValid: true }
        } else if (period < 60) {
            return { label: t('timeframes.minuteCountValue', { count: period }), isValid: true }
        } else {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `Invalid period passed to GetShortTimeframeName ${period}`
            )
            const { localeKey, localeParamsKey } = defaultValueParams || {
                localeKey: 'timeframes.minuteCountValue',
                localeParamsKey: 'count'
            }

            return defaultValueParams?.localeParamsKey
                ? { label: t(localeKey, { [localeParamsKey as string]: period }), isValid: false }
                : { label: t(localeKey), isValid: false }
        }
    }

    public static IsValidTimeframe(period: number): boolean {
        if (period <= 0) {
            return false
        }
        if (period > 0 && period <= 30) {
            //M1-M30
            return true
        }
        if (period > 30 && period < 60) {
            //FIXME: this is temporary restriction, waiting for backend to provide data
            return false
            //M35-M55
            return period % 5 === 0
        }
        if (period >= 60 && period < DateUtils.MinutesPerDay) {
            //FIXME: this is termporary restriction, waiting for backend to provide data
            //FIXME: this is temporary restriction, waiting for backend to provide data
            return period === 60 || period === 240
            //H1-H23
            return period % 60 === 0
        }
        if (period >= DateUtils.MinutesPerDay && period < DateUtils.MinutesPerWeek) {
            //FIXME: this is temporary restriction, waiting for backend to provide data
            return period === DateUtils.MinutesPerDay
            //D1 - D6
            return period % DateUtils.MinutesPerDay === 0
        }
        if (period >= DateUtils.MinutesPerWeek && period < this.MonthlyTimeframe) {
            //FIXME: this is temporary restriction, waiting for backend to provide data
            return period === DateUtils.MinutesPerWeek
            //W1-W4
            return period % DateUtils.MinutesPerWeek === 0 && period <= 4 * DateUtils.MinutesPerWeek
        } else if (period >= this.MonthlyTimeframe) {
            //FIXME: this is temporary restriction, waiting for backend to provide data
            return period === this.MonthlyTimeframe
            //monthly
            return period % this.MonthlyTimeframe === 0 && [1, 2, 3, 4, 6].includes(period / this.MonthlyTimeframe)
        }
        return false
    }

    public static shouldTimeBeUsed(tf: number): boolean {
        return tf < DateUtils.MinutesPerDay
    }

    public static CanBeBuiltFrom(largerTimeframe: number, smallerTimeframe: number): boolean {
        if (
            !this.IsValidTimeframe(largerTimeframe) ||
            !this.IsValidTimeframe(smallerTimeframe) ||
            largerTimeframe <= smallerTimeframe
        ) {
            StrangeSituationNotifier.NotifyAboutUnexpectedSituation(
                `Invalid timeframes passed to CanBeBuiltFrom larger: ${largerTimeframe} smaller: ${smallerTimeframe}`
            )
            return false
        }

        // Building any TF lower or equal to 30 from M1
        if (smallerTimeframe === 1 && largerTimeframe <= 30) {
            return true
        }

        // Building any TF lower than H1 from M5
        if (smallerTimeframe === 5 && largerTimeframe <= 60) {
            return largerTimeframe % 5 === 0
        }

        // Building any TF lower than H1 from M15 - this is basically for M45 since M15 and M30 are downloadable
        if (smallerTimeframe === 15 && largerTimeframe <= 60) {
            return largerTimeframe % 15 === 0
        }

        if (smallerTimeframe === 30 && largerTimeframe <= 60) {
            return largerTimeframe % 30 === 0
        }

        // Building any TF between H1 and H23 from H1
        if (smallerTimeframe === 60 && largerTimeframe > 60 && largerTimeframe <= DateUtils.MinutesPerDay) {
            return largerTimeframe % 60 === 0
        }

        // Building any TF between D1 and D6 from H1, we do now need to build D7 (WK) since it can be downloaded directly
        if (
            smallerTimeframe === 60 &&
            largerTimeframe >= DateUtils.MinutesPerDay &&
            largerTimeframe <= 6 * DateUtils.MinutesPerDay
        ) {
            return largerTimeframe % DateUtils.MinutesPerDay === 0
        }

        // Building any TF between 2 weeks and 4 weeks from 1 week
        if (
            smallerTimeframe === DateUtils.MinutesPerWeek &&
            largerTimeframe >= 2 * DateUtils.MinutesPerWeek &&
            largerTimeframe <= 4 * DateUtils.MinutesPerWeek
        ) {
            return largerTimeframe % DateUtils.MinutesPerWeek === 0
        }

        // Building any TF multiple of this.MonthlyTimeframe from H1
        if (smallerTimeframe === 60 && largerTimeframe % this.MonthlyTimeframe === 0) {
            return true
        }

        // Building any TF multiple of H4 from H4
        if (smallerTimeframe === 240 && largerTimeframe % 240 === 0) {
            return true
        }

        // Building any TF multiple of D1 from D1
        if (smallerTimeframe === DateUtils.MinutesPerDay && largerTimeframe % DateUtils.MinutesPerDay === 0) {
            return true
        }

        // Building monthly and weekly TFs from H1
        // eslint-disable-next-line sonarjs/prefer-single-boolean-return
        if (
            smallerTimeframe === 60 &&
            (largerTimeframe % DateUtils.MinutesPerWeek === 0 || largerTimeframe % this.MonthlyTimeframe === 0)
        ) {
            return true
        }

        return false
    }
}
