import { FC } from 'react'
import { Toggle, Modal, Flex, Typography } from '@fto/ui'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { useAppDispatch, useAppSelector } from '@root/hooks/useStoreHook'
import { $useCrowdin } from '@root/store/appSettings/selectors'
import { SET_CROWDIN } from '@root/store/appSettings/slice'

export const AdminSettingsModal: FC = () => {
    const useCrowdin = useAppSelector($useCrowdin)
    const dispatch = useAppDispatch()

    function setShowCrowdin(v: boolean) {
        dispatch(SET_CROWDIN(v))
    }

    function handleToggleChange(v: boolean) {
        setShowCrowdin(v)
    }

    return (
        <Modal size={'xs'} name={MODAL_NAMES.dashboard.adminSettings} withCloseIcon>
            <Modal.Header> Admin settings </Modal.Header>
            <Modal.Content>
                <Flex gap={20} alignItems={'center'} justifyContent={'flex-start'}>
                    <Typography>Show translation popups</Typography>
                    <Toggle checked={useCrowdin} onChange={handleToggleChange} />
                </Flex>
            </Modal.Content>
        </Modal>
    )
}
