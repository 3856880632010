import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { t } from 'i18next'

import { Modal, removeModal, InputField, SimpleDropdown, Flex, Typography } from '@fto/ui'
import { Icon } from '@fto/icons'

import MessageInformer from '@root/components/MessageInformer'
import { MODAL_NAMES } from '@root/constants/modalNames'
import { fireMixpanelEvent } from '@root/utils/api'

import { GlobalProjectJSONAdapter } from '@fto/lib/ProjectAdapter/GlobalProjectJSONAdapter'
import TimeframeStore from '@fto/lib/store/timeframe'
import GlobalChartsController from '@fto/lib/globals/GlobalChartsController'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { TimeframeUtils } from '@fto/lib/ft_types/common/TimeframeUtils'
import { SyncProjectManager } from '@fto/lib/ProjectAdapter/ProjectSync/SyncProjectManager'

import { TIME_FRAMES } from '@fto/chart_components/constants/timeframes'

import { convertMinutesToType } from './utils/convertMinutesToType'
import { convertToMinutes } from './utils/convertToMinutes'
import { getOptions, TypeAvailableTypes } from './utils/getOptions'

import styles from './index.module.scss'

type Props = {
    value?: string
    isChange: boolean
}

const CustomTimeframeModal: FC<Props> = ({ value, isChange }) => {
    const [inputValue, setInputValue] = useState<number | ''>(value === '' ? '' : Number(value))
    const [timeframeType, setTimeframeType] = useState<TypeAvailableTypes>('minutes')
    const [errorMessage, setErrorMessage] = useState('')
    const [previousTimeframeType, setPreviousTimeframeType] = useState<TypeAvailableTypes>('minutes')

    const onClose = useCallback(() => {
        removeModal(MODAL_NAMES.chart.customTimeFrame)
    }, [])

    const timeFrameTypeOptions = useMemo(() => {
        return getOptions(t)
    }, [])

    const onValueChange = useCallback((timeframeValue: string) => {
        const regex = /^\d+$/

        const isEmptyString = timeframeValue === ''

        try {
            const numberValue = Number(timeframeValue)

            if (timeframeValue.match(regex) || timeframeValue === '') {
                return setInputValue(isEmptyString ? '' : numberValue)
            }
        } catch {
            throw new StrangeError('Invalid custom timeframe value')
        }

        return null
    }, [])

    useEffect(() => {
        if (inputValue !== '') {
            const convertedToMinutes = convertToMinutes(inputValue, previousTimeframeType)
            const convertedToThisType = convertMinutesToType(convertedToMinutes, timeframeType)

            setInputValue(convertedToThisType < 1 ? 1 : convertedToThisType)
        }

        setPreviousTimeframeType(timeframeType)
    }, [timeframeType])

    const calculatedTimeframeByMinutes = useMemo(() => {
        const convertedValue = convertToMinutes(inputValue || 0, timeframeType)

        return TimeframeUtils.GetTimeframeNameLabel(convertedValue, {
            localeKey: 'customTimeframes.modal.calculator.invalidValue'
        })
    }, [inputValue, timeframeType])

    const handleSubmit = useCallback(() => {
        const activeChart = GlobalChartsController.Instance.getActiveChart()
        if (!activeChart) {
            throw new StrangeError('No active chart in CustomTimeframeModal')
        }

        const timeframeNumber = Number(inputValue)

        if (isNaN(timeframeNumber)) {
            throw new StrangeError('Invalid timeframe value')
        }

        const fractionShortLabel = t(`customTimeframes.modal.options.${timeframeType}.shortLabel`)

        const convertedValue = convertToMinutes(timeframeNumber, timeframeType)

        const newTimeframe = {
            longName: calculatedTimeframeByMinutes.label,
            shortName: `${inputValue}${fractionShortLabel}`,
            value: convertedValue,
            id: Date.now()
        }

        const isValid = TimeframeUtils.IsValidTimeframe(convertedValue)

        const allTimeframes = [...TIME_FRAMES, ...TimeframeStore.customTimeframes.map((tf) => tf.value)]

        if (isValid && allTimeframes.includes(newTimeframe.value)) {
            // NOTE: That is CHANGE flow (if tf exists)
            activeChart.SetTimeframe(timeframeType === 'minutes' ? timeframeNumber : convertedValue)
            TimeframeStore.setTimeframe(newTimeframe.value)
            SyncProjectManager.Instance.SaveProject()
                .then(() => {
                    fireMixpanelEvent('custom_tf_changed', { custom_timeframe: newTimeframe.shortName })
                })
                .catch(() => {
                    throw new StrangeError('Autosave request error after changing custom timeframe')
                })

            return removeModal(MODAL_NAMES.chart.customTimeFrame)
        }

        if (isValid) {
            // NOTE: this adds new custom TimeFrame flow
            GlobalProjectJSONAdapter.Instance.addCustomTimeFrame(newTimeframe)

            TimeframeStore.updateCustomTimeFrames((prev) => {
                const isAlreadyExist = prev.some((tf) => tf.value === newTimeframe.value)
                return isAlreadyExist ? prev : [...prev, newTimeframe]
            })

            activeChart.SetTimeframe(
                timeframeType === 'minutes' ? timeframeNumber : convertToMinutes(timeframeNumber, timeframeType)
            )

            TimeframeStore.setTimeframe(newTimeframe.value)

            SyncProjectManager.Instance.SaveProject()
                .then(() => {
                    fireMixpanelEvent('custom_tf_added', { custom_timeframe: newTimeframe.shortName })
                })
                .catch(() => {
                    throw new StrangeError('Autosave request error after creating custom timeframe')
                })

            return removeModal(MODAL_NAMES.chart.customTimeFrame)
        } else {
            setErrorMessage(t('customTimeframes.modal.validationErrors.unavailable'))
            return fireMixpanelEvent('custom_tf_failed', { custom_timeframe: newTimeframe.shortName })
        }
    }, [inputValue, timeframeType, calculatedTimeframeByMinutes])

    return (
        <Modal
            name={MODAL_NAMES.chart.customTimeFrame}
            withCloseIcon
            size='md'
            withClickOutside={false}
            onSubmit={handleSubmit}
            withEnterSubmit
        >
            <Modal.Header withBorderBottom>
                {t(`customTimeframes.modal.titles.${isChange ? 'change' : 'add'}`)}
            </Modal.Header>
            <Modal.Content withBorderBottom>
                <Flex gap={16} alignItems='center' className={styles.Info}>
                    <div className={styles.InfoSeparator}>
                        <Icon name='info' size={18} color='var(--color-primary-500)' />
                    </div>
                    <Flex direction='column' gap={8}>
                        <Typography type='text-semibold'>{t('customTimeframes.modal.infoBlock.title')}</Typography>
                        <Flex direction='column' gap={28}>
                            <ul className={styles.List}>
                                <li>
                                    <Typography type='interface-regular'>
                                        {t('customTimeframes.modal.infoBlock.minutesAnyValue')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography type='interface-regular'>
                                        {t('customTimeframes.modal.infoBlock.minutesDividedValue')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography type='interface-regular'>
                                        {t('customTimeframes.modal.infoBlock.hoursAnyValue')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography type='interface-regular'>
                                        {t('customTimeframes.modal.infoBlock.daysDividedValue')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography type='interface-medium'>
                                        {t('customTimeframes.modal.infoBlock.weeksAnyValue')}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography type='interface-medium'>
                                        {t('customTimeframes.modal.infoBlock.monthMaxValue')}
                                    </Typography>
                                </li>
                            </ul>
                            <div>
                                <Typography type='text-semibold' block>
                                    {t('customTimeframes.modal.infoBlock.needsMore')}
                                </Typography>
                                <Typography type='interface-medium' block>
                                    {t('customTimeframes.modal.infoBlock.letUsKnow')}
                                </Typography>
                            </div>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex direction='column' gap={16}>
                    <div className={styles.GridContainer}>
                        <InputField
                            id='customTimeframe'
                            error={!inputValue || !!errorMessage || !calculatedTimeframeByMinutes.isValid}
                            type='number'
                            step={1}
                            onChange={onValueChange}
                            value={inputValue}
                            size='medium'
                            autoFocus
                        />
                        <SimpleDropdown
                            classNames={{
                                header: styles.TypesList,
                                root: styles.TypesListRoot,
                                content: styles.TypesListContent
                            }}
                            value={timeframeType}
                            options={timeFrameTypeOptions}
                            onChange={setTimeframeType}
                            disableBackdropChartActivity
                        />
                    </div>
                    {inputValue !== '' && (
                        <Typography
                            block
                            align='center'
                            color={calculatedTimeframeByMinutes.isValid ? 'gray-1000' : 'red-500'}
                        >
                            {calculatedTimeframeByMinutes.label}
                        </Typography>
                    )}
                    <MessageInformer
                        disappearing
                        type='error'
                        isShown={!!errorMessage}
                        onClose={() => setErrorMessage('')}
                    >
                        <Typography type='interface-medium' color='red-600' align='center' block>
                            {t('customTimeframes.modal.validationErrors.unavailable')}
                        </Typography>
                    </MessageInformer>
                </Flex>
            </Modal.Content>
            <Modal.Controls
                onClose={onClose}
                submitText={
                    isChange ? t('customTimeframes.modal.controls.change') : t('customTimeframes.modal.controls.add')
                }
            />
        </Modal>
    )
}

export default CustomTimeframeModal
