import { NotImplementedError } from '@fto/lib/utils/common_utils'
import { ITechnicalIndicatorsProcRec } from '../CommonProcRecInterfaces/ITechnicalIndicatorsProcRec'
import StrangeError from '@fto/lib/common/common_errors/StrangeError'
import { GlobalMovingAverageCache, WmaInfo } from '@fto/lib/globals/GlobalMovingAverageCache'
import { TPriceType, MacdMode, TMAType } from '../CommonTypes'
import IFMBarsArray from '@fto/lib/ft_types/data/data_arrays/chunked_arrays/IFMBarsArray'
import { ICurrentSymbolAndTFInfoProcRec } from '../CommonProcRecInterfaces/ICurrentSymbolInfoProcRec'
import { API_InvalidInitialization } from '../errors/API_InvalidInitialization'
import ISymbolData from '@fto/lib/ft_types/data/ISymbolData'
import { BasicProcRecImplementation } from './BasicProcRecImplementation'
import { IProcRecsEveryImplementationNeeds } from './IProcRecsEveryImplementationNeeds'

export class TechnicalIndicatorsImplementation
    extends BasicProcRecImplementation
    implements ITechnicalIndicatorsProcRec
{
    public GetImplementation(): ITechnicalIndicatorsProcRec {
        return {
            iMACD: this.iMACD.bind(this),
            GetMA: this.GetMA.bind(this),
            LRCChannelParams: this.LRCChannelParams.bind(this)
        }
    }

    protected override generateDName(): string {
        return `API_TechnicalIndicators_${super.generateDName()}`
    }

    private currSymbolAndTFInfoProcRec: ICurrentSymbolAndTFInfoProcRec

    private barsArrayGetter: () => IFMBarsArray
    private symbolDataGetter: () => ISymbolData

    private get bars() {
        if (!this.barsArrayGetter) {
            throw new API_InvalidInitialization('barsArrayGetter is not assigned.')
        }
        const bars = this.barsArrayGetter()
        if (!bars) {
            throw new API_InvalidInitialization('barsArrayGetter returned null.')
        }
        return bars
    }

    private get symbolData() {
        if (!this.symbolDataGetter) {
            throw new API_InvalidInitialization('symbolDataGetter is not assigned.')
        }
        const symbolData = this.symbolDataGetter()
        if (!symbolData) {
            throw new API_InvalidInitialization('symbolDataGetter returned null.')
        }
        return symbolData
    }

    constructor(
        procRecsEveryoneNeeds: IProcRecsEveryImplementationNeeds,
        currSymbolAndTFInfoProcRec: ICurrentSymbolAndTFInfoProcRec,
        barsArrayGetter: () => IFMBarsArray,
        symbolDataGetter: () => ISymbolData
    ) {
        super(procRecsEveryoneNeeds)
        this.currSymbolAndTFInfoProcRec = currSymbolAndTFInfoProcRec
        this.barsArrayGetter = barsArrayGetter
        this.symbolDataGetter = symbolDataGetter
    }

    private GetPrice(index: number, priceType: TPriceType): number {
        return this.currSymbolAndTFInfoProcRec.GetPrice(index, priceType)
    }

    private get timeframe(): number {
        return this.bars.DataDescriptor.timeframe
    }

    public LRCChannelParams(
        shift: number,
        period: number,
        priceType: TPriceType
    ): { StartValue: number; EndValue: number; Height: number; Top: number; Bottom: number } {
        let sum_x = 0,
            sum_y = 0,
            sum_xy = 0,
            sum_x2 = 0,
            y = 0

        let i = shift

        // Calculating sums for regression line
        for (let x = 0; x < period; x++) {
            y = this.GetPrice(i, priceType)
            sum_x += x
            sum_y += y
            sum_xy += x * y
            sum_x2 += x * x
            i++
        }

        // Calculating regression line
        const b = (period * sum_xy - sum_x * sum_y) / (period * sum_x2 - sum_x * sum_x)
        const a = (sum_y - b * sum_x) / period

        // Calculating channel height
        i = shift
        let max = 0

        for (let x = 0; x < period; x++) {
            y = a + b * x
            const z = Math.abs(this.GetPrice(i, priceType) - y)
            if (z > max) max = z
            i++
        }

        return { StartValue: a + b * period, EndValue: a, Height: max, Top: a + max, Bottom: a - max }
    }

    public iMACD(
        symbol: string | null,
        timeframe: number | null,
        fastEmaPeriod: number,
        slowEmaPeriod: number,
        signalPeriod: number,
        appliedPrice: TPriceType,
        mode: MacdMode,
        index: number
    ): number {
        throw new NotImplementedError('Method not implemented. iMACD')
    }

    private getWmaValueForCurrentTestingIndex(
        shift: number,
        period: number,
        applyTo: TPriceType,
        weightForPeriod: number
    ): number {
        const currentValueInCache = GlobalMovingAverageCache.getInstance().getCurrWmaValue(
            this.symbolData.symbolInfo.SymbolName,
            this.timeframe,
            0,
            shift,
            period,
            applyTo,
            this.bars.LastItemInTestingIndex
        )

        let sum: number
        const lastWeightedPrice: number = this.GetPrice(0, applyTo) * period

        if (currentValueInCache) {
            sum = currentValueInCache.value - currentValueInCache.lastWeightedPrice + lastWeightedPrice
        } else {
            sum = this.calcWMASum(0, period, shift, applyTo)
        }

        const wmaInfo: WmaInfo = {
            value: sum,
            lastWeightedPrice: lastWeightedPrice
        }
        GlobalMovingAverageCache.getInstance().saveWmaSum(
            this.symbolData.symbolInfo.SymbolName,
            this.timeframe,
            0,
            shift,
            period,
            applyTo,
            this.bars.LastItemInTestingIndex,
            wmaInfo
        )

        return sum / weightForPeriod
    }

    private getWmaValue(
        index: number,
        shift: number,
        period: number,
        applyTo: TPriceType,
        weightForPeriod: number
    ): number {
        const currentValueInCache = GlobalMovingAverageCache.getInstance().getCurrWmaValue(
            this.symbolData.symbolInfo.SymbolName,
            this.timeframe,
            index,
            shift,
            period,
            applyTo,
            this.bars.LastItemInTestingIndex
        )

        if (currentValueInCache) {
            return currentValueInCache.value / weightForPeriod
        } else {
            const wmaInfo: WmaInfo = {
                value: this.calcWMASum(index, period, shift, applyTo),
                lastWeightedPrice: this.GetPrice(index, applyTo) * period
            }
            GlobalMovingAverageCache.getInstance().saveWmaSum(
                this.symbolData.symbolInfo.SymbolName,
                this.timeframe,
                index,
                shift,
                period,
                applyTo,
                this.bars.LastItemInTestingIndex,
                wmaInfo
            )
            return wmaInfo.value / weightForPeriod
        }
    }

    private calcWMASum(startIndex: number, period: number, shift: number, applyTo: TPriceType): number {
        let sum = 0
        for (let i = 0; i < period; i++) {
            sum += this.GetPrice(startIndex + i + shift, applyTo) * (period - i)
        }
        return sum
    }

    public GetMA(index: number, shift: number, period: number, maType: TMAType, applyTo: TPriceType, prev = 0): number {
        let i: number
        let k: number
        switch (maType) {
            case TMAType.ma_SMA: {
                // SMA

                if (index + shift + period > this.bars.LastItemInTestingIndex || index + shift < 0) {
                    return 0
                }

                let sum = 0
                const prevSum = GlobalMovingAverageCache.getInstance().getPrevSum(
                    this.symbolData.symbolInfo.SymbolName,
                    this.timeframe,
                    index,
                    shift,
                    period,
                    applyTo,
                    this.bars.LastItemInTestingIndex
                )

                if (prevSum) {
                    sum =
                        prevSum - this.GetPrice(index + shift + period, applyTo) + this.GetPrice(index + shift, applyTo)
                } else {
                    for (let local_i = index + shift; local_i < index + shift + period; local_i++) {
                        sum += this.GetPrice(local_i, applyTo)
                    }
                }

                GlobalMovingAverageCache.getInstance().saveCurrentSum(
                    this.symbolData.symbolInfo.SymbolName,
                    this.timeframe,
                    index,
                    shift,
                    period,
                    applyTo,
                    this.bars.LastItemInTestingIndex,
                    sum
                )

                return sum / period
            }
            case TMAType.ma_EMA: {
                // EMA
                k = 2 / (period + 1)
                i = index + shift
                if (i >= this.bars.LastItemInTestingIndex || i < 0) {
                    return 0
                } else {
                    if (prev === 0) {
                        return this.GetPrice(i, applyTo)
                    } else {
                        return prev + k * (this.GetPrice(i, applyTo) - prev)
                    }
                }
            }
            case TMAType.ma_SSMA: {
                // SSMA
                if (index + shift + period > this.bars.LastItemInTestingIndex || index + shift < 0) {
                    return 0
                } else {
                    if (prev === 0) {
                        // Recursive call with ma_SMA to initialize SSMA
                        return this.GetMA(index, shift, period, TMAType.ma_SMA, applyTo, prev)
                    } else {
                        return (prev * (period - 1) + this.GetPrice(index + shift, applyTo)) / period
                    }
                }
            }
            case TMAType.ma_WMA: {
                // WMA
                if (index + shift + period > this.bars.LastItemInTestingIndex || index + shift < 0) {
                    return 0
                }

                const currWeight = (period / 2) * (1 + period)
                if (index === 0) {
                    return this.getWmaValueForCurrentTestingIndex(shift, period, applyTo, currWeight)
                } else {
                    return this.getWmaValue(index, shift, period, applyTo, currWeight)
                }
            }
            default: {
                throw new StrangeError('Unsupported MA type')
            }
        }
    }
}
