import { createSelector } from '@reduxjs/toolkit'

import { RootState } from '@root/store'
import { Roles, User } from '@root/types/user'
import { hasAdminRole } from '@root/store/auth/constants'

const authStore = (state: RootState) => state.auth
const userEntity = (state: RootState) => state.auth.user

export const $isUserLoaded = createSelector(authStore, ({ userIsLoaded }) => userIsLoaded)

export const $getUser = createSelector(userEntity, (user): User => {
    if (user) {
        return user
    }

    // NOTE: be free to adjust list of properties
    return {
        displayName: '',
        email: '',
        userId: '',
        customAuth: false,
        subscriptionStatus: 'Unknown',
        subscriptionId: NaN,
        isTrialExpired: false,
        trialPeriodEnd: '',
        role: Roles.TRANSLATOR,
        AccountType: 'BetaTester',
        tenantId: [],
        IsNewUser: true
    }
})

export const $isRegularUser = createSelector($getUser, ({ AccountType }) => AccountType !== 'BetaTester')

export const $getCode = createSelector(authStore, (auth) => auth.confirmationCode)

const ROLES_TO_SEE_SETTINGS: Roles[] = [Roles.TRANSLATOR]

export const $userCanSeeExtendedSettings = createSelector($getUser, ({ role }) => ROLES_TO_SEE_SETTINGS.includes(role))

export const $getUserSubscriptionStatus = createSelector(
    $getUser,
    ({ subscriptionStatus, isTrialExpired, subscriptionId, IsNewUser }) => {
        const isSubscriptionStatusActive = subscriptionStatus === 'Active'
        const isSubscriptionStatusCanceled = subscriptionStatus === 'Canceled'

        const isActive = isSubscriptionStatusActive && isTrialExpired && subscriptionId !== null

        return {
            isActive: isActive,
            isTrial: (isSubscriptionStatusActive || isSubscriptionStatusCanceled) && !isTrialExpired,
            isTrialExpired: isTrialExpired,
            isNewUser: subscriptionStatus === 'Unknown' && subscriptionId === null && IsNewUser
            /* #NOTE: commented code for subscriptions with trial (#TRIAL) */
            // isNewUser: subscriptionStatus === 'Unknown' && subscriptionId === null && !isTrialExpired
        }
    }
)
